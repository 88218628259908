<template>
  <div class="action-button-split-group export-button">
    <action-button
      button-display="BTN_PRIMARY"
      message="Export"
      :custom-classes="{'btn-fixed-width-m': true, 'action-button-split-left': true }"
      :is-disabled="unverifiedDocumentSelected"
      :is-loading="attemptingExport"
      :error-message="unverifiedDocumentSelected ? 'Metrics can only be exported for verified documents' : null"
      @onClick="() => getExportMethod()(defaultMainExportOption)"
    />
    <action-button-options
      :custom-classes="{'action-button-split-right': true}"
      :options="mainExportOptions"
      :is-disabled="unverifiedDocumentSelected"
      :default-option-id="defaultMainExportOption"
      @optionSelected="onExportOptionSelected"
    >
      <template #extraContent>
        <hr
          class="mt-medium"
          :style="{'margin': 0}"
        >
        <radio-form-group
          :options="formatOptions"
          title="Format"
          :default-option-id="selectedFormat"
          @set-selected-option-id="setSelectedFormat"
          @click="(e) => e.stopPropagation()"
        />
      </template>
    </action-button-options>

    <confirmation-modal
      :show="showWarning"
      :message="warningMessage"
      modal-type="WARNING"
      title="Warning"
      @close="closeWarningModal"
      @confirm="onAcceptWarning"
    />
  </div>
</template>
<script>
import exportOptions from '@/store/helpers/mapping/exportOptions';
import ActionButtonOptions from '@/components/general/buttons/ActionButtonOptions.vue';
import ActionButton from '@/components/general/buttons/ActionButton.vue';
import { mapActions } from 'vuex';
import { errorMessages } from '@/store/helpers/display/toastMessages';
import { useToast } from 'vue-toastification';
import ConfirmationModal from '../modals/ConfirmationModal.vue';
import RadioFormGroup from '../forms/RadioFormGroup.vue';

export default {
  name: 'Export',
  components: {
    ActionButton,
    ActionButtonOptions,
    ConfirmationModal,
    RadioFormGroup,
  },
  props: {
    documentRequests: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showWarning: false,
      warningMessage: '',
      toast: useToast(),
      attemptingExport: false,
      defaultMainExportOption: exportOptions.DEFAULT_MAIN_EXPORT_OPTION,
      currentExportOption: exportOptions.DEFAULT_MAIN_EXPORT_OPTION,
      selectedFormat: null,
      formatOptions: exportOptions.FORMAT_OPTIONS,
      mainExportOptions: exportOptions.MAIN_EXPORT_OPTIONS.map((option) => ({
        key: option.id,
        label: option.label,
      })),
      warnings: exportOptions.WARNINGS,
    };
  },
  computed: {
    unverifiedDocumentSelected() {
      return (this.documentRequests.some((dr) => dr.is_verified === false));
    },
  },
  watch: {
    selectedFormat(newFormat) {
      const exportAllMetricsOption = this.mainExportOptions.find((option) => option.key === 'EXPORT_ALL_METRICS');
      exportAllMetricsOption.disabled = (newFormat === 'EXPORT_AS_EXCEL'); // Disable export or all metrics if excel format
    },
  },
  beforeMount() {
    this.selectedFormat = 'EXPORT_AS_EXCEL';
  },
  methods: {
    ...mapActions({
      exportMany: 'documentRequest/exportMany',
      generateDocumentsReport: 'documentRequest/generateDocumentsReport',
    }),
    /**
     * Export flow will generate the files to download via
     * either the exportMany method or the generateReport method.
     * generateReport method will be used when the user selects an Excel export,
     * otherwise will opt for the exportMetrics method.
     */
    getExportMethod() {
      return this.selectedFormat === 'EXPORT_AS_EXCEL' ? () => this.generateReport() : () => this.exportMetrics(this.currentExportOption);
    },
    onExportOptionSelected(e) {
      this.$log.debug(`Export triggered with with option: (${e.optionKey}) - selected format: ${this.selectedFormat}`);
      this.currentExportOption = e.optionKey; // EXPORT_VERIFIED_METRICS, EXPORT_ALL_METRICS, etc..
      if (this.warnings[this.currentExportOption]) {
        this.warningMessage = this.warnings[this.currentExportOption];
        this.showWarning = true;
        return;
      }

      const beginExport = this.getExportMethod();
      beginExport();
    },
    // Mainly used for CSVs and JSON
    exportMetrics(exportOption) {
      this.$log.debug('Exporting metrics');
      this.attemptingExport = true;
      const documentRequestIds = this.documentRequests.map((req) => req.document_request_id);
      this.$log.debug('Exporting:', documentRequestIds, this.selectedFormat, exportOption);

      if (!documentRequestIds.length) {
        return Promise.resolve();
      }

      return this.exportMany({
        documentRequestIds,
        format: this.selectedFormat,
        includeUnverified: exportOption === 'EXPORT_ALL_METRICS',
      }).then((res) => {
        this.$log.info('Successfully exported metrics: ', res);
      }).catch((e) => {
        this.toast.error(`${errorMessages.FAILED_EXPORT_METRICS}: ${e.message}`);
        this.$log.error('Export error', e);
      }).finally(() => { this.attemptingExport = false; });
    },
    // Used for Excel
    generateReport() {
      this.$log.debug('Generating report');
      this.attemptingExport = true;
      const documentRequestIds = this.documentRequests.map((req) => req.document_request_id);
      if (!documentRequestIds.length) {
        return Promise.resolve();
      }
      return this.generateDocumentsReport({ documentRequestIds })
        .then((r) => {
          this.$log.info('Generate report request successful, downloading report');
          const link = document.createElement('a');
          link.href = r.uri;
          link.click();
        })
        .catch((e) => {
          this.$log.error(`Generate report failed, error: ${e.message}`);
          this.toast.error(errorMessages.FAILED_GENERATE_REPORT);
        })
        .finally(() => { this.attemptingExport = false; });
    },
    closeWarningModal() {
      this.showWarning = false;
    },
    onAcceptWarning() {
      const beginExport = this.getExportMethod();
      beginExport();
      this.closeWarningModal();
    },
    setSelectedFormat(formatId) {
      this.selectedFormat = formatId;
    },

  },
};

</script>
<style lang="scss">
.export-button {
  .dropdown-content {
    min-width: 240px;
  }
}
</style>
