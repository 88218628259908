<template>
  <modal
    :show="show"
    title="Delete Document"
    :custom-classes="['modal-bg-side-nav']"
    @close="close"
  >
    <template
      v-if="true"
      class="modal-body"
    >
      <p class="">
        You have marked the following {{ selectedDocumentRequests.length > 1 ? 'documents' : 'document' }} for deletion.

        They will be permanently removed from the Freyda platform.
      </p>
      <p>
        This action cannot be undone.
      </p>
      <ul class="modal-list">
        <li
          v-for="(item, idx) in selectedDocumentRequests"
          :key="idx"
        >
          <span style="margin-left: 8px; margin-right: 6px;">{{ idx + 1 }})</span> <span class="bold">{{ item.name }}</span>
        </li>
      </ul>
    </template>
    <template #footer>
      <div class="modal-footer has-2-col">
        <action-button
          button-display="BTN_PRIMARY"
          message="CANCEL"
          :custom-classes="{'top-nav-item': true}"
          @onClick="close"
        />
        <action-button
          button-display="BTN_NEG"
          message="DELETE"
          :custom-classes="{'top-nav-item': true}"
          :is-loading="confirmDeleteIsLoading"
          @onClick="confirmDelete"
        />
      </div>
    </template>
  </modal>
</template>
<script>
import Modal from './Modal.vue';
import ActionButton from '../general/buttons/ActionButton.vue';

export default {
  components: {
    ActionButton,
    Modal,
  },
  props: {
    confirmDeleteIsLoading: {
      type: Boolean,
      default: false,
    },
    show: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      default: 'Verify',
    },
    selectedDocumentRequests: {
      type: Array,
      required: true,
    },
  },
  emits: ['close', 'confirmDelete'],
  methods: {
    close() {
      this.$emit('close');
    },
    confirmDelete() {
      this.$emit('confirmDelete', this.selectedDocumentRequests.map((dr) => dr.document_request_id));
    },
  },
};
</script>
