<template>
  <div class="action-button-split-group requeue-button">
    <action-button
      data-testid="re-queue-action-button"
      button-display="BTN_PRIMARY"
      message="Re-Process"
      :custom-classes="{'action-button-split-left': true, 'btn-fixed-width-m': true }"
      :is-loading="attemptingRequeue"
      :is-disabled="isDisabled"
      :error-message="isDisabled ? 'You must select a document type via the dropdown' : null"
      @onClick="() => showConfirmation = true"
    />
    <action-button-options
      :custom-classes="{'action-button-split-right': true}"
      :options="[]"
    >
      <template #extraContent>
        <div @click="(e) => e.stopPropagation()">
          <radio-form-group
            :options="requeueOptions"
            default-option-id="DEFAULT"
            @set-selected-option-id="setRequeueOption"
            @click="(e) => e.stopPropagation()"
          />
          <vue-multi-select
            class="table-cell-dropdown document-type-selection-dropdown"
            :model-value="selectedDocumentType"
            :options="Object.values(documentTypesToDisplayMapping)"
            :allow-empty="false"
            placeholder="Select document type"
            @update:model-value="setSelectedDocumentType"
          />
        </div>
      </template>
    </action-button-options>
    <confirmation-modal
      :show="showConfirmation"
      title="WARNING"
      message=""
      modal-type="WARNING"
      @close="() => showConfirmation = false"
      @confirm="confirmReQueue()"
    >
      <template #customBodyContent>
        <div>
          <div class="requeue-warning-message">
            <span><b>All data extracted from your selected documents will be lost.</b></span>
            <div>
              <span>Are you sure you would like to re-process the documents listed below
                <span v-if="selectedRequeueOption === 'WITH_SELECTED_DOC_TYPE'">
                  as the following document type:
                  <b>{{ selectedDocumentType }} </b>
                </span>?
              </span>
            </div>
          </div>
          <div class="re-queue-document-list">
            <p
              v-for="dr in documentRequests"
              :key="dr.document_request_id"
            >
              <b>{{ dr.name }}</b>
            </p>
          </div>
        </div>
      </template>
    </confirmation-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { useToast } from 'vue-toastification';
import VueMultiSelect from 'vue-multiselect';
import { errorMessages } from '@/store/helpers/display/toastMessages';
import ActionButton from '../general/buttons/ActionButton.vue';
import ActionButtonOptions from '../general/buttons/ActionButtonOptions.vue';
import RadioFormGroup from '../forms/RadioFormGroup.vue';
import ConfirmationModal from '../modals/ConfirmationModal.vue';

export default {
  components: {
    ActionButton,
    ActionButtonOptions,
    VueMultiSelect,
    RadioFormGroup,
    ConfirmationModal,
  },
  props: {
    documentRequests: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      toast: useToast(),
      selectedDocumentType: '',
      documentTypes: {},
      attemptingRequeue: false,
      selectedRequeueOption: 'DEFAULT',
      showConfirmation: false,
      requeueOptions: [
        { id: 'DEFAULT', label: 'Do not change document types' },
        { id: 'WITH_SELECTED_DOC_TYPE', label: 'Re-process documents as:' },
      ],
    };
  },
  computed: {
    ...mapGetters({
      documentTypesToDisplayMapping: 'documentTypes/documentTypesToDisplayMapping',
    }),
    isDisabled() {
      return this.selectedRequeueOption === 'WITH_SELECTED_DOC_TYPE' && this.selectedDocumentType === '';
    },
  },
  beforeMount() {
    this.getDocumentTypes()
      .then((response) => {
        this.documentTypes = response;
      })
      .catch((e) => {
        this.$log.error(e);
      });
  },
  methods: {
    ...mapActions({
      requeueDocuments: 'documents/requeueDocuments',
      getDocumentTypes: 'documentTypes/lazyGet',
    }),
    setSelectedDocumentType(newValue) {
      this.selectedDocumentType = newValue;
    },
    setRequeueOption(newValue) {
      this.selectedRequeueOption = newValue;
    },
    requeue(documentType = null) {
      this.attemptingRequeue = true;
      let reqBody = [];
      // If documentType is not provided, we requeue the documents as their existing type
      if (documentType === null) {
        reqBody = this.documentRequests.map((dr) => ({
          document_request_id: dr.document_request_id,
          document_type: dr.document_type,
        }));
      } else {
        const docTypeKey = Object.keys(this.documentTypes).find((key) => this.documentTypes[key].display === documentType);
        reqBody = this.documentRequests.map((dr) => ({
          document_request_id: dr.document_request_id,
          document_type: docTypeKey,
        }));
      }
      this.requeueDocuments(reqBody)
        .then((response) => {
          const totalSucceeded = this.documentRequests.length - response.failed_documents.length;
          if (totalSucceeded === 0) {
            throw new Error();
          }
          this.toast.success(`Successfully started re-processing ${totalSucceeded} document(s)`);
          if (response.failed_documents.length > 0) { this.handleFailedDocuments(response.failed_documents); }
          this.$router.push({ name: 'Upload' });
        }).catch(() => {
          this.toast.error(errorMessages.REQUEUE_DOCUMENTS);
        }).finally(() => {
          this.attemptingRequeue = false;
        });
    },
    handleFailedDocuments(failedDocumentRequestIds) {
      const failedDocumentNames = this.documentRequests
        .filter((dr) => failedDocumentRequestIds.includes(dr.document_request_id))
        .map((dr) => dr.name);
      if (failedDocumentNames.length === 0) { return; }
      const total = failedDocumentNames.length;
      const displayCount = 10;
      let message = `Failed to re-process: \n${failedDocumentNames.slice(0, displayCount).join('\n')}`;
      if (total > displayCount) { message = `${message}\n ... and ${total - displayCount} others.`; }
      this.toast.error(message);
    },
    confirmReQueue() {
      this.showConfirmation = false;
      this.requeue(this.selectedRequeueOption === 'DEFAULT' ? null : this.selectedDocumentType);
    },
  },
};
</script>
<style lang="scss">
.requeue-button {
  .dropdown-content {
    right: 0 !important;
    min-width: 300px;
    margin-top: 3px
  }

  .document-type-selection-dropdown {
    margin: 0 20px;
    width: 260px;
  }
  .btn-fixed-width-m {
    width: 160px;
  }

  .re-queue-document-list {
    background: #e4e4e4;
    text-align: left;
    padding: 0 10px;
    max-height: 100px;
    overflow-y: scroll;
  }

  .modal-container {
    margin-top: 25vh; // Modal is slightly bigger here. Top margin is default 30vh, decreasing to center align.
  }

  .requeue-warning-message {
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-bottom: 8px;
  }
}
</style>
