<template>
  <td class="table-col-0">
    <checkbox-input
      :id="`documentSelect-${rowIdx}`"
      :model-value="rowData.selected"
      @update:modelValue="onToggleSelectedOnRow()"
    />
  </td>
  <td>
    <router-link
      :to="getLinkToDocument()"
      class="cell-link"
    >
      {{ fields[0].text }}
    </router-link>
  </td>
  <td
    v-for="(cell, fieldIndex) in fields.slice(1)"
    v-show="!hiddenColumnIndices.includes(fieldIndex + 1)"
    :key="fieldIndex"
  >
    <span
      v-if="!cell.verifyStatus"
      :class="cell.hasGreyedText ? 'hint' : ''"
    >
      {{ cell.text }}
    </span>
    <span
      v-else
      style="display:flex; align-items: center"
    >
      <span>{{ cell.text }}</span>
      <img
        class="table-icon"
        alt=""
        :src="verifyStatusIcon(cell.verifyStatus)"
      >
    </span>
  </td>
</template>
<script>
import CheckboxInput from '@/components/forms/CheckboxInput.vue';

export default {
  components: { CheckboxInput },
  props: {
    rowIdx: {
      type: Number,
      required: true,
    },
    rowData: {
      type: Object,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
    hiddenColumnIndices: {
      type: Array,
      required: true,
    },
  },
  emits: ['toggleSelectedOnRow'],
  data() {
    return {
      statusIconMapping: {
        DOC_EDITED: 'tick-yellow.svg',
        DOC_VERIFIED: 'tick-green.svg',
      },
    };
  },
  methods: {
    onToggleSelectedOnRow() {
      this.$emit('toggleSelectedOnRow');
    },
    verifyStatusIcon(status) {
      return this.getAssetUrl(this.statusIconMapping[status]);
    },
    getAssetUrl(filename) {
      if (!filename) return '';
      // eslint-disable-next-line global-require, import/no-dynamic-require
      return require(`../../assets/${filename}`);
    },
    getLinkToDocument() {
      return {
        name: 'VerifyTabledDocument',
        params: {
          documentRequestId: this.rowData.document_request_id,
          breadcrumbIndex: 0,
        },
        query: {
          previousView: this.$route.name,
        },
      };
    },
  },
};
</script>
