<template>
  <div class="verification-centre">
    <div class="main-container verification-centre-nav">
      <h1>{{ $route.name }}</h1>
    </div>
    <document-table
      :key="stage"
      :stage="stage"
    />
  </div>
</template>
<script>
import DocumentTable from '../components/verification/DocumentTable.vue';

export default {
  components: {
    DocumentTable,
  },
  props: {
    stage: {
      type: String,
      default: '',
      required: false,
    },
  },
};

</script>
