<template>
  <div>
    <div class="table-content">
      <div class="verification-filters-container">
        <vue-multi-select
          class="document-type-filter-multiselect"
          placeholder="Filter By Document Type..."
          :model-value="selectedDocumentType"
          :options="Object.values(documentTypesToDisplayMapping)"
          @update:model-value="updateSelectedDocumentType"
        />
        <search-bar
          placeholder="Search Documents..."
          @update-search="(query) => searchString = query"
        />
      </div>
      <div
        class="table-container"
        :style="`padding-bottom: ${selectedDocumentRequests.length > 0 ? '72px' : '30px' }`"
      >
        <table id="Documents">
          <tr>
            <th class="table-col-0">
              <checkbox-input
                id="documentSelectAll"
                :model-value="operatingRows.length === 0 ? false : allSelected"
                @update:modelValue="allSelectPressed"
              />
            </th>
            <th
              v-for="(header, index) in headers"
              v-show="!hiddenColumnIndices.includes(index)"
              :key="header.key"
              @click="columnClicked(header.key, index)"
            >
              <span class="document-table-column-header">
                <span class="column-header-text">
                  {{ header.display }}
                </span>
                <span class="icon-container column-header-icon">
                  <triangle-icon
                    :class="sortIconClass(index)"
                    size="0.75x"
                  />
                </span>
              </span>
            </th>
          </tr>
          <tr
            v-for="(row, index) in operatingRows"
            :key="index"
          >
            <document-table-row
              :row-idx="index"
              :row-data="row"
              :fields="row.fields"
              :hidden-column-indices="hiddenColumnIndices"
              @toggleSelectedOnRow="toggleSelectedOnRow(row.id)"
            />
          </tr>
          <caption>Documents passing through the verification flow</caption>
        </table>
      </div>
      <div v-if="documentTypesStoreIsReady">
        <div v-if="documentStoreIsLoading">
          <loading-overlay
            message="Loading available documents..."
            :overlay-opaque="true"
            :style="{'background': 'transparent', 'position': 'unset'}"
          />
        </div>
        <div
          v-else
          v-show="operatingRows.length === 0"
          class="hint spaced"
        >
          <p>
            There are currently no documents in the verification stage.<br>
            Documents may still be in the processing stage.
          </p>
        </div>
      </div>
      <div v-else>
        <loading-overlay
          message="Retrieving available document types..."
          :overlay-opaque="true"
          :style="{'background': 'transparent', 'position': 'unset'}"
        />
      </div>
    </div>
    <document-table-actions
      :confirm-delete-is-loading="confirmDeleteIsLoading"
      :selected-document-requests="selectedDocumentRequests"
      :stage="stage"
      :trash-documents-is-loading="trashDocumentsIsLoading"
      :restore-documents-is-loading="restoreDocumentsIsLoading"
      @verify="verify"
      @confirmDelete="confirmDelete"
      @trashDocuments="onTrashDocuments"
      @restoreDocuments="onRestoreDocuments"
      @unselectAll="unselectAll"
    />
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { TriangleIcon } from '@zhuowenli/vue-feather-icons';
import { useToast } from 'vue-toastification';
import DocumentTableRow from '@/components/verification/DocumentTableRow.vue';
import { errorMessages, successMessages } from '@/store/helpers/display/toastMessages';
import { VERIFIED_STATUS_INDEX } from '@/store/helpers/request/transformers/transformDocuments';
import VueMultiSelect from 'vue-multiselect';
import SearchBar from '@/components/general/SearchBar.vue';
import CheckboxInput from '../forms/CheckboxInput.vue';
import { allowedStates } from '../../store/helpers/storeState';
import DocumentTableActions from './DocumentTableActions.vue';
import LoadingOverlay from '../general/LoadingOverlay.vue';

export default {
  components: {
    DocumentTableRow,
    DocumentTableActions,
    SearchBar,
    CheckboxInput,
    TriangleIcon,
    VueMultiSelect,
    LoadingOverlay,
  },
  props: {
    stage: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      columnIndexSelected: 0,
      columnSortType: 0,
      confirmDeleteIsLoading: false,
      trashDocumentsIsLoading: false,
      restoreDocumentsIsLoading: false,
      searchString: '',
      selectedDocumentType: '',
      statusIconMapping: {
        DOC_EDITED: 'tick-yellow.svg',
        DOC_VERIFIED: 'tick-green.svg',
      },
      // headers: ['PDF Name', 'Due Date', 'Fund', 'Fund Manager', 'Upload Date', 'Upload By', 'Verify Status', 'Verified By'],
      rows: [],
      toast: useToast(),
    };
  },
  computed: {
    ...mapGetters({
      all: 'documents/all',
      sorted: 'documents/sorted',
      storeStatus: 'documents/storeStatus',
      tableHeaders: 'documents/tableHeaders',
      documentTypesToDisplayMapping: 'documentTypes/documentTypesToDisplayMapping',
      documentTypesStoreIsReady: 'documentTypes/isReady',
    }),
    hiddenColumnIndices() {
      return this.headers.reduce((prev, cur, idx) => [...prev, ...(cur.hidden ? [idx] : [])], []);
    },
    documentStoreIsLoading() {
      return this.storeStatus === allowedStates.IS_LOADING;
    },
    headers() {
      return this.tableHeaders(this.selectedDocumentTypeKey);
    },
    operatingRows() {
      const showRow = (r) => {
        let matchesSearch = true;
        if (this.searchString !== '') {
          matchesSearch = this.rowMatchesSearchString(r);
        }
        return this.selectedDocumentTypeKey ? r.documentType === this.selectedDocumentTypeKey && matchesSearch : matchesSearch;
      };
      return this.sorted.filter(showRow);
    },
    selectedRows() {
      return this.operatingRows.filter((r) => r.selected);
    },
    selectedDocumentRequests() {
      return this.selectedRows.map((r) => ({
        document_request_id: r.document_request_id,
        name: r.fields[0].text,
        is_verified: r.fields[VERIFIED_STATUS_INDEX].verifyStatus === 'DOC_VERIFIED',
        document_type: r.documentType,
      }));
    },
    allSelected() {
      return this.selectedRows.reduce((prev, cur) => (cur.selected ? prev + 1 : prev), 0) === this.operatingRows.length;
    },
    selectedDocumentTypeKey() {
      if (!this.selectedDocumentType) {
        return null;
      }
      return Object.keys(this.documentTypesToDisplayMapping).find((key) => this.documentTypesToDisplayMapping[key] === this.selectedDocumentType);
    },
  },
  mounted() {
    this.confirmDeleteIsLoading = false;
    this.getDocumentTypes()
      .catch((e) => {
        this.$log.error(e);
        this.toast.error('Error fetching document types');
      });

    this.initialiseDocuments();
  },
  methods: {
    ...mapActions({
      createNewDocumentRequestQueue: 'documentRequestQueue/createNewQueue',
      deleteDocumentRequests: 'documents/deleteDocumentRequests',
      generateDocumentsReport: 'documentRequest/generateDocumentsReport',
      getSettings: 'localisation/lazyInit',
      init: 'documents/init',
      selectOnSorted: 'documents/selectOnSorted',
      sortBy: 'documents/sortBy',
      toggleSelectedOnRow: 'documents/toggleSelectedOnRow',
      getDocumentTypes: 'documentTypes/lazyGet',
      changeDocumentRequestsState: 'documents/changeDocumentRequestsState',
      removeDocumentRequests: 'documents/removeDocumentRequests',
    }),
    async initialiseDocuments() {
      await this.getSettings()
        .catch(() => {
          this.toast.error('Error fetching user settings, initialising with defaults');
          this.initSettingsWithDefault();
        });
      return this.init(this.stage)
        .catch((e) => {
          this.$log.error(e);
          this.toast.error('Error fetching documents');
        });
    },
    sortIconClass(index) {
      return {
        'icon-sort': true,
        'sort-show': this.columnSortType && this.columnIsSorted(index),
        'sort-asc': this.columnSortType === 1,
        'sort-desc': this.columnSortType === 2,
      };
    },
    columnClicked(key, index) {
      if (index !== this.columnIndexSelected) {
        this.columnIndexSelected = index;
        this.columnSortType = 1;
      } else {
        this.columnSortType += 1;
        if (this.columnSortType > 2) {
          this.columnSortType = 0;
        }
      }
      this.sortBy({ columnKey: key, columnIndex: index, direction: this.columnSortType });
    },
    columnIsSorted(index) {
      return this.columnIndexSelected === index;
    },
    rowMatchesSearchString(r) {
      const ss = this.searchString.toLowerCase();
      let hasMatch = false;
      r.fields.forEach((field) => {
        if (field.text && field.text.toLowerCase().includes(ss)) {
          hasMatch = true;
        }
      });

      return hasMatch;
    },
    verifyStatusIcon(status) {
      return this.getAssetUrl(this.statusIconMapping[status]);
    },
    getAssetUrl(filename) {
      if (!filename) return '';
      // eslint-disable-next-line global-require, import/no-dynamic-require
      return require(`../../assets/${filename}`);
    },
    newDocumentTypeSelected(key) {
      this.searchString = '';
      this.unselectAll();
      this.documentTypeSelected = key;
      this.$router.replace({ query: { type: key } });
    },
    allSelectPressed() {
      return this.allSelected ? this.unselectAll() : this.selectAll();
    },
    selectAll() {
      this.selectOnSorted(true);
    },
    unselectAll() {
      this.selectOnSorted(false);
    },
    isString(x) {
      return Object.prototype.toString.call(x) === '[object String]';
    },
    getSelectedRowIds() {
      const selectedStrIds = [];
      this.selectedRows.forEach((r) => selectedStrIds.push(r.document_request_id));
      this.$log.info('Selected row ids:', selectedStrIds);
      return selectedStrIds;
    },
    verify() {
      const selectedIds = this.getSelectedRowIds();
      if (!selectedIds.length) {
        return;
      }

      this.createNewDocumentRequestQueue(selectedIds);
      const documentRequestId = String(selectedIds[0]);
      this.navigateToVerifyDocumentRequest(documentRequestId);
    },
    navigateToVerifyDocumentRequest(documentRequestId) {
      return this.$router.push(
        {
          name: 'VerifyTabledDocument',
          params: {
            documentRequestId,
            breadcrumbIndex: 0,
          },
          query: {
            previousView: this.$route.name,
            type: this.documentTypeSelected,
          },
        },
      );
    },
    async confirmDelete(ids) {
      this.confirmDeleteIsLoading = true;
      await this.deleteDocumentRequests(ids);

      const msg = `Successfully deleted ${ids.length} documents`;
      this.$log.info(msg);
      this.toast.success(msg);

      this.confirmDeleteIsLoading = false;
      await this.initialiseDocuments(); // fetch updated table from backend
    },
    onTrashDocuments() {
      this.trashDocumentsIsLoading = true;
      const selectedIds = this.getSelectedRowIds();

      if (!selectedIds.length) {
        return Promise.resolve();
      }
      return this.changeDocumentRequestsState({ ids: selectedIds, stage: 'TRASHED' })
        .then(() => {
          this.toast.success(successMessages.TRASHED_DOCUMENTS);
          this.removeDocumentRequests(selectedIds);
        })
        .catch((e) => {
          this.$log.error(`onTrashDocuments failed, error: ${e.message}`);
          this.toast.error(errorMessages.UPDATE_STATE);
        })
        .finally(() => { this.trashDocumentsIsLoading = false; });
    },
    onRestoreDocuments() {
      this.restoreDocumentsIsLoading = true;
      const selectedIds = this.getSelectedRowIds();

      if (!selectedIds.length) {
        return Promise.resolve();
      }
      return this.changeDocumentRequestsState({ ids: selectedIds, stage: 'EXT_DONE' })
        .then(() => {
          this.toast.success(successMessages.RESTORED_DOCUMENTS);
          this.removeDocumentRequests(selectedIds);
        })
        .catch((e) => {
          this.$log.error(`onRestoreDocuments failed, error: ${e.message}`);
          this.toast.error(errorMessages.UPDATE_STATE);
        })
        .finally(() => { this.restoreDocumentsIsLoading = false; });
    },
    updateSelectedDocumentType(value) {
      this.selectedDocumentType = value;
    },
  },
};

</script>
<style src="vue-multiselect/dist/vue-multiselect.css">
</style>
