<template>
  <table-action-bar
    v-show="numSelected > 0"
    :text="`${numSelected} ${numSelected > 1 ? 'documents' : 'document'} selected`"
    @on-click-x="unselectAll"
  >
    <template #actions>
      <action-button
        v-if="showOption('delete')"
        button-display="BTN_NEG"
        message="Delete"
        :custom-classes="{'btn-group-item': true, 'btn-fixed-width-m': true}"
        @onClick="initialDelete"
      />
      <action-button
        v-if="showOption('verify')"
        button-display="BTN_SECONDARY"
        message="Verify"
        :custom-classes="{'btn-group-item': true, 'btn-fixed-width-m': true}"
        @onClick="verify"
      />
      <export
        v-if="showOption('export')"
        :document-requests="selectedDocumentRequests"
      />
      <action-button
        v-if="showOption('trash')"
        button-display="BTN_NEG"
        message="Trash"
        :custom-classes="{'btn-group-item': true, 'btn-fixed-width-m': true}"
        :is-loading="trashDocumentsIsLoading"
        @onClick="trashDocuments"
      />
      <action-button
        v-if="showOption('restore')"
        button-display="BTN_SECONDARY"
        message="Restore"
        :custom-classes="{'btn-group-item': true, 'btn-fixed-width-m': true}"
        :is-loading="restoreDocumentsIsLoading"
        @onClick="restoreDocuments"
      />
      <re-queue
        v-if="showOption('requeue')"
        :document-requests="selectedDocumentRequests"
      />
    </template>
  </table-action-bar>
  <delete-document-request-modal
    :confirm-delete-is-loading="confirmDeleteIsLoading"
    :selected-document-requests="selectedDocumentRequests"
    :show="showDeleteDocumentRequestModal"
    @close="closeDeleteDocumentRequestModal"
    @confirmDelete="confirmDelete"
  />
</template>
<script>
import DeleteDocumentRequestModal from '../modals/DeleteDocumentRequestModal.vue';
import ActionButton from '../general/buttons/ActionButton.vue';
import Export from './Export.vue';
import ReQueue from '../requeue/ReQueue.vue';
import TableActionBar from '../general/TableActionBar.vue';

export default {
  components: {
    ActionButton,
    DeleteDocumentRequestModal,
    Export,
    ReQueue,
    TableActionBar,
  },
  props: {
    confirmDeleteIsLoading: {
      type: Boolean,
      default: false,
    },
    selectedDocumentRequests: {
      type: Array,
      required: true,
    },
    stage: {
      type: String,
      required: true,
    },
    trashDocumentsIsLoading: {
      type: Boolean,
      default: false,
    },
    restoreDocumentsIsLoading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['verify', 'confirmDelete', 'trashDocuments', 'restoreDocuments', 'unselectAll'],
  data() {
    return {
      showDeleteDocumentRequestModal: false,
    };
  },
  computed: {
    numSelected() {
      return this.selectedDocumentRequests.length;
    },
  },
  watch: {
    confirmDeleteIsLoading(newConfirmDeleteIsLoading) {
      // ConfirmDelete button is no longer loading (so close modal)
      if (newConfirmDeleteIsLoading === false) {
        this.closeDeleteDocumentRequestModal();
      }
    },
  },
  mounted() {
    this.showDeleteDocumentRequestModal = false;
  },
  methods: {
    closeDeleteDocumentRequestModal() {
      this.showDeleteDocumentRequestModal = false;
    },
    confirmDelete(ids) {
      this.$emit('confirmDelete', ids);
    },
    initialDelete() {
      this.showDeleteDocumentRequestModal = true;
    },
    verify() {
      this.$emit('verify');
    },
    trashDocuments() {
      this.$emit('trashDocuments');
    },
    restoreDocuments() {
      this.$emit('restoreDocuments');
    },
    unselectAll() {
      this.$emit('unselectAll');
    },
    showOption(optionType) {
      let allowedStates = [];
      if (optionType === 'delete') {
        allowedStates = ['TRASHED'];
      } else if (optionType === 'export') {
        allowedStates = ['EXT_DONE', 'ARCHIVED'];
      } else if (optionType === 'requeue') {
        allowedStates = ['EXT_DONE', 'ARCHIVED'];
      } else if (optionType === 'trash') {
        allowedStates = ['EXT_DONE', 'ARCHIVED', 'EXT_MANUAL_QA'];
      } else if (optionType === 'verify') {
        allowedStates = ['EXT_DONE', 'EXT_MANUAL_QA'];
      } else if (optionType === 'restore') {
        allowedStates = ['ARCHIVED', 'TRASHED'];
      }
      return allowedStates.includes(this.stage);
    },
  },
};
</script>
